import React from "react"
import { TransitionPortal } from "gatsby-plugin-transition-link"
import { Location } from "@reach/router"
import Navigation from "@components/shared/Navigation/Navigation"
import FooterSection from "@components/shared/FooterSection/FooterSection"

export default ({ children }) => (
  <>
    <TransitionPortal>
      <Location>
        {({ location }) => <Navigation location={location} />}
      </Location>
    </TransitionPortal>
    {children}
    <FooterSection />
  </>
)
