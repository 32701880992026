import React from "react"
import NavigationGroupItem from "@components/shared/Navigation/NavigationGroupItem/NavigationGroupItem"

const NavigationGroup = ({ items }) => (
  <div className="sub-menu">
    <ul className="sub-menu-grid">
      {items.map((item, index) => (
        <NavigationGroupItem key={index} position={index} {...item} />
      ))}
    </ul>
  </div>
)

export default NavigationGroup
