/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions  */
import "./SidebarGroupItem.scss"
import React from "react"
import Link from "@components/shared/Link/Link"
import iconArrowRight from "@assets/icons/ic_arrow-right.svg"

const LinkNavigation = ({ label, description, url, onNavigateUrl }) => (
  <Link className="list-group-item" to={url} onClick={onNavigateUrl}>
    <div className="d-flex flex-row pt-1 pb-3 mb-3 border-bottom border-light">
      <div className="d-flex flex-column flex-grow-1">
        <div className="d-flex w-100 justify-content-between">
          <h6 className="font-weight-600 mb-0 text-capitalize">{label}</h6>
        </div>
        <div className="line-height-normal">
          <small className="text-vtf-blue-darker mb-2">{description}</small>
        </div>
      </div>
    </div>
  </Link>
)

const DepthNavigation = ({ label, level, description, onNavigateLevel }) => (
  <div
    style={{ cursor: "pointer" }}
    className="list-group-item"
    onClick={() => onNavigateLevel(level)}
  >
    <div className="d-flex flex-row pt-1 pb-3 mb-3 border-bottom border-light">
      <div className="d-flex flex-column flex-grow-1">
        <div className="d-flex w-100 justify-content-between">
          <h6 className="font-weight-600 mb-0 text-capitalize">{label}</h6>
        </div>
        <div className="line-height-normal">
          <small className="text-vtf-blue-darker mb-2">{description}</small>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <img alt={label} src={iconArrowRight} height="15" />
      </div>
    </div>
  </div>
)

const SidebarGroupItem = props =>
  props.url ? <LinkNavigation {...props} /> : <DepthNavigation {...props} />

export default SidebarGroupItem
