import {
  compose,
  converge,
  curry,
  find,
  map,
  mergeAll,
  objOf,
  path,
  propEq,
} from "ramda"

/**
 * Takes a list of values and runs it through a shuffling function, returning the shuffled copy
 * @param {f(Array<any>)} randomizingFn - shuffling function
 * @param {Array<any>} xs - values to be shuffled
 */
const shuffler = curry((randomizingFn, xs) => {
  var idx = -1
  var len = xs.length
  var position
  var result = []
  while (++idx < len) {
    position = Math.floor((idx + 1) * randomizingFn())
    result[idx] = result[position]
    result[position] = xs[idx]
  }
  return result
})

/**
 * @param {Array<any>} _ - a specialized version of `shuffler`, with a shuffling function baked in
 */
export const shuffle = shuffler(Math.random)

/**
 * Logs intermediary data to the console. Specially useful when logging a step from a composition chain
 * e.g.:
 *       given >>> F :: a => b and G :: b => c
 *       and >>> compose(G, log, F)(value)
 *
 * the composition chain would log the result immediately after F and pass it along directly after to G without interference
 * @param {string} label - a prefix for the console log message
 * @param {array<any>} x - value to be outputted to the console
 */
export const log = curry((label, x) => {
  console.log(label, x)
  return x
})

/**
 * Stops the debugger on its point of execution. Specially useful when debugging a step from a composition chain
 * e.g.:
 *       given >>> F :: a => b and G :: b => c
 *       and >>> compose(G, debug, F)(value)
 *
 * the composition chain would bring the debugger up immediately after F and then proceed execution to G without interference
 * @param {array<any>} x
 */
export const debug = x => {
  debugger
  return x
}

/**
 *
 * @param {string} id of the markdown file section
 * @param {Object} data coming from graphql query
 * @returns {Object} the node data belonging to the selected section id
 */
export const contentFilterBySection = (id, data) =>
  data.find(element => element.node.frontmatter.section === id).node

/**
 *
 * @param {string} id of the markdown file section
 * @param {Object} data coming from graphql query
 * @returns {Array<Object>} the filtered nodes belonging to the selected section id
 */
export const contentFilter = (id, data) =>
  data.filter(element => element.node.frontmatter.section === id)

/**
 * Selects edges from a GraphQL Query Result
 * @param {Object} query - GraphQL query result
 * @returns {Array<Object>} query - matched query edges
 */
export const getEdgesForQuery = query => path([query, "edges"])

/**
 * Selects edges from a specific GraphQL query key
 * @param {Object} key - GraphQL query key
 * @returns {Array<Object>} query - GraphQL Query Result
 */
const getEdges = key => path([key, "edges"])

/**
 * A specialized version of `getEdges` that selects data coming from Markdown
 * @param {Object} _ - GraphQL Query Result
 * @returns {Object} a selection of the matching Markdown data
 */
export const getMarkdownEdges = getEdges("allMarkdownRemark")

/**
 * A specialized version of `getEdges` that selects data coming from Images
 * @param {Object} _ - GraphQL Query Result
 * @returns {Object} a selection of the matching Image data
 */
export const getImageEdges = getEdges("allImageSharp")

const getEdgeFrontmatter = path(["node", "frontmatter"])
const getEdgeInternal = path(["node", "internal"])
const getEdgeFluid = path(["node", "fluid"])
const getEdgeHtml = compose(objOf("html"), path(["node", "html"]))

/**
 * Selects data pertaining to a specific list of edges
 * @param {Array<Edges>} _ - list of edges to be selected upon
 * @returns {Object} the data that matches the selection criteria
 */
export const getQueryData = map(
  converge(compose(mergeAll, Array), [
    getEdgeFrontmatter,
    getEdgeHtml,
    getEdgeInternal,
  ])
)

/**
 * Selects a list of responsive images returned from a GraphQL Query
 * @param {Object} _ - GraphQL Query Edges
 * @returns {Array<Object>} a list of Fluid Image objects
 */
export const getImageQueryFluidData = map(
  converge(compose(mergeAll, Array), [getEdgeFluid])
)

/**
 * Selects the lower level of data. i.e. the Markdown file keys
 * @param {string} section - value of the section key in the Markdown file
 * @param {Array<Object>} content - list of Markdown content
 */
export const getSectionDataFor = curry((section, content) =>
  find(propEq("section", section), content)
)
