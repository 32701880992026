import "./FooterSection.scss"
import React from "react"
import Link from "@components/shared/Link/Link"
import { Container, Row, Col } from "reactstrap"
import imageLogo from "@assets/logo/ITI_Logo.svg"
import { compose, head, omit, tail } from "ramda"
import { useStaticQuery, graphql } from "gatsby"
import { getEdgesForQuery, getQueryData, getSectionDataFor } from "@utils/utils"
import FooterGroup from "@components/shared/Footer/FooterGroup/FooterGroup"

const FooterSection = () => {
  const data = useStaticQuery(graphql`
    query footer {
      navigation: allMarkdownRemark(
        filter: {
          frontmatter: {
            path: { eq: "/ui" }
            section: { eq: "ui-section-navigation" }
            published: { eq: true }
            lang: { eq: "de" }
          }
        }
      ) {
        edges {
          node {
            frontmatter {
              section
              services {
                description
                label
                url
              }
              technologies {
                description
                label
                url
              }
              about {
                description
                label
                url
              }
              jobs {
                description
                label
                url
              }
            }
          }
        }
      }
      details: allMarkdownRemark(
        filter: {
          frontmatter: {
            path: { eq: "/ui" }
            section: { eq: "ui-section-footer" }
            published: { eq: true }
            lang: { eq: "de" }
          }
        }
      ) {
        edges {
          node {
            frontmatter {
              section
              details {
                address
                email
                legalName
                telephone
              }
              impressum {
                label
                url
              }
              dataPrivacy {
                label
                url
              }
            }
          }
        }
      }
    }
  `)

  const footerNavigation = compose(
    getQueryData,
    getEdgesForQuery("navigation")
  )(data)
  const footerNavigationData = getSectionDataFor(
    "ui-section-navigation",
    footerNavigation
  )
  const footerDetails = compose(getQueryData, getEdgesForQuery("details"))(data)
  const { details, impressum, dataPrivacy } = getSectionDataFor(
    "ui-section-footer",
    footerDetails
  )

  const navigationGroups = omit(["section", "html"], footerNavigationData)

  return (
    <section className="footer-section-wrapper small">
      <div className="container pb-3 pt-4 py-md-5 small">
        <div className="footer-section-grid">
          <div className="footer-section-grid-item-1">
            <div className="footer-section-flex-item-1 small ml-3 ml-sm-0">
              <img
                className="footer-iti-logo mb-4"
                src={imageLogo}
                alt={`details.legalName`}
              />
              <p className="">{details.address}</p>
              <p className=" mb-0">{details.telephone}</p>
              <a href={`mailto:${details.email}`}>{details.email}</a>
            </div>
          </div>
          <div className="footer-section-grid-item-2">
            <Row className="w-100 m-0">
              {Object.keys(navigationGroups).map((group, index) => (
                <Col key={index} xs={11} sm={6} lg={3}>
                  <FooterGroup
                    header={head(navigationGroups[group])}
                    items={tail(navigationGroups[group])}
                  />
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
      <div className="border-top border-lines small">
        <div className="footer-section-grid-item-3">
          <Container className="p-3 small">
            <Row className="footer-section-flex-item-3 justify-content-center">
              <Col xs={11} sm={6} md={8}>
                {details.legalName}
              </Col>
              <Col xs={11} sm={6} md={4} className="d-flex">
                <div className="mx-auto mx-md-0 ml-md-auto mt-3 mt-sm-0">
                  <Link to={impressum.url} className="font-weight-bolder">
                    {impressum.label}
                  </Link>
                  <Link
                    to={dataPrivacy.url}
                    className="pl-5 font-weight-bolder"
                  >
                    {dataPrivacy.label}
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </section>
  )
}

export default FooterSection
