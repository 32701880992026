import React from "react"
import Link from "@components/shared/Link/Link"

const FooterGroupItem = ({ label, url }) => (
  <li className="mb-1">
    <Link to={url}>{label}</Link>
  </li>
)

export default FooterGroupItem
