/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions  */
import "./Sidebar.scss"
import React from "react"
import Link from "@components/shared/Link/Link"

import imageLogoSmall from "@assets/logo/ITI_Logo_Small.svg"
import iconNavigationClose from "@assets/icons/ic_close.svg"

const Sidebar = ({ children, isOpen, handleClose }) => {
  return (
    <div className={`sidebar ${isOpen ? "sidebar-open" : ""} p-3`}>
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between align-items-center">
          <Link className="navbar-brand" to="/">
            <img
              className="logo"
              src={imageLogoSmall}
              height={30}
              alt="IT&I Software"
            />
          </Link>
          <img
            className="float-right"
            src={iconNavigationClose}
            height={20}
            alt="Schließen"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="d-flex flex-column flex-fill">
          <div className="my-4 flex-fill">{children}</div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
