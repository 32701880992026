import React from "react"
import SidebarGroupItem from "@components/shared/Navigation/Sidebar/SidebarGroupItem/SidebarGroupItem"

const SidebarGroup = ({ level, items, onNavigateLevel, onNavigateUrl }) => (
  <div className="list-group">
    {items.map((item, index) => (
      <SidebarGroupItem
        key={index}
        level={level}
        onNavigateLevel={onNavigateLevel}
        onNavigateUrl={onNavigateUrl}
        {...item}
      />
    ))}
  </div>
)

export default SidebarGroup
