import React from "react"
import Link from "@components/shared/Link/Link"
import FooterGroupItem from "@components/shared/Footer/FooterGroupItem/FooterGroupItem"

const FooterGroup = ({ header: { label, url }, items }) => (
  <>
    {(url.indexOf('://') > -1)
        ?   <Link to={url} className="font-weight-bolder xsmall text-uppercase" target="_blank" rel="noopener noreferrer">
                {label}
            </Link>
        :   <Link to={url} className="font-weight-bolder xsmall text-uppercase">
                {label}
            </Link>
    }
    <ul className="list-unstyled small mt-2">
      {items.map((item, index) => (
        <FooterGroupItem key={index} position={index} {...item} />
      ))}
    </ul>
  </>
)

export default FooterGroup
