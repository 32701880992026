import "./NavigationGroupItem.scss"
import React from "react"
import Link from "@components/shared/Link/Link"

const NavigationGroupItem = ({ label, description, position, url }) => (
  <li className={`sub-menu-item sub-menu-item-${position + 1}`}>
      {(url.indexOf('://') > -1)
        ?   <Link className="sub-menu-item-header font-weight-bolder" to={url} target="_blank" rel="noopener noreferrer">
                <div className="font-weight-bolder">{label}</div>
                <div className="sub-menu-item-text font-weight-normal">
                    {description.split(/\n/g).map((line, key) => (
                    <div key={key}>{line}</div>
                    ))}
                </div>
            </Link>
        :   <Link className="sub-menu-item-header font-weight-bolder" to={url}>
                <div className="font-weight-bolder">{label}</div>
                <div className="sub-menu-item-text font-weight-normal">
                    {description.split(/\n/g).map((line, key) => (
                    <div key={key}>{line}</div>
                    ))}
                </div>
            </Link>
      }

  </li>
)

export default NavigationGroupItem
