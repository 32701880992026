/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions  */
import "./Navigation.scss"
import imageLogo from "@assets/logo/ITI_Logo.svg"
import imageLogoSmall from "@assets/logo/ITI_Logo_Small.svg"
import iconNavigationOpen from "@assets/icons/ic_open.svg"
import iconArrowLeft from "@assets/icons/ic_arrow-left.svg"
import React from "react"
import Link from "@components/shared/Link/Link"
import classnames from "classnames"
import {
  compose,
  any,
  equals,
  head,
  flip,
  init,
  omit,
  map,
  path,
  prop,
  startsWith,
  tail,
} from "ramda"
import { Button, Navbar, Nav, Container, Row } from "reactstrap"
import { usePrevious, useWindowScroll } from "react-use"
import NavigationGroup from "@components/shared/Navigation/NavigationGroup/NavigationGroup"
import Sidebar from "@components/shared/Navigation/Sidebar/Sidebar"
import SidebarGroup from "@components/shared/Navigation/Sidebar/SidebarGroup/SidebarGroup"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import { useStaticQuery, graphql } from "gatsby"
import { getEdgesForQuery, getQueryData, getSectionDataFor } from "@utils/utils"

const Navigation = props => {
  const data = useStaticQuery(graphql`
    query uiNavigation {
      ui: allMarkdownRemark(
        filter: {
          frontmatter: {
            path: { eq: "/ui" }
            section: { eq: "ui-section-navigation" }
            published: { eq: true }
            lang: { eq: "de" }
          }
        }
      ) {
        edges {
          node {
            frontmatter {
              section
              sidebar {
                description
                label
                level
              }
              services {
                description
                label
                url
              }
              technologies {
                description
                label
                url
              }
              about {
                description
                label
                url
              }
              jobs {
                description
                label
                url
              }
            }
          }
        }
      }
    }
  `)

  const ui = compose(getQueryData, getEdgesForQuery("ui"))(data)
  const uiNavigationData = getSectionDataFor("ui-section-navigation", ui)

  const sidebarGroups = compose(init, prop("sidebar"))(uiNavigationData)
  const navigationGroups = omit(
    ["jobs", "section", "sidebar"],
    uiNavigationData
  )
  const jobsNavigation = compose(head, prop("jobs"))(uiNavigationData)

  const { y: scrollY } = useWindowScroll()
  const scrollThreshold = 25 // Change this if you'd like to have a minimun tolerance
  const prevY = usePrevious(scrollY)
  const isExpanded = scrollY < scrollThreshold || scrollY < prevY || !scrollY

  const indicatedPaths = ["/about", "/services", "/technologies"]
  const currentPath = path(["location", "pathname"], props)

  const isIndicatorVisible = compose(
    any(equals(true)),
    map(flip(startsWith)(currentPath))
  )(indicatedPaths)

  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false)
  const [sidebarLevel, setSidebarLevel] = React.useState(null)

  const handleClose = () => {
    setIsSidebarOpen(false)
    enableBodyScroll()
  }

  const handleCloseOnTimeout = () => {
    setTimeout(handleClose, 240)
  }

  return (
    <>
      <Sidebar
        isExpanded={isExpanded}
        isOpen={isSidebarOpen}
        handleClose={handleClose}
      >
        {!sidebarLevel ? (
          <>
            <SidebarGroup
              level={sidebarLevel}
              items={sidebarGroups}
              onNavigateLevel={setSidebarLevel}
            />
            <Link
              activeClassName="active"
              to={jobsNavigation.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button color="dark" size="md">
                {jobsNavigation.label}
              </Button>
            </Link>
          </>
        ) : (
          <>
            <div className="mb-3">
              <button
                className="btn btn-link text-blue-dark px-0 d-flex align-items-center"
                onClick={() => setSidebarLevel(null)}
              >
                <img
                  alt="Zurück"
                  src={iconArrowLeft}
                  height={15}
                  className="mr-1"
                />
                Zurück
              </button>
            </div>
            <SidebarGroup
              level={sidebarLevel}
              items={navigationGroups[sidebarLevel]}
              onNavigateUrl={handleCloseOnTimeout}
            />
          </>
        )}
      </Sidebar>
      <Navbar
        color="light"
        light
        expand="md"
        className={`bg-white ${
          !isExpanded ? "navbar-collapsed" : ""
        } shadow small fixed-top`}
      >
        <Container>
          <Row className={`w-100 mx-auto`}>
            <Link
              className="navbar-brand d-flex flex-row align-items-center"
              to="/"
            >
              {isExpanded ? (
                <img
                  className="logo"
                  src={imageLogo}
                  height="30"
                  width="122"
                  alt="IT&I Software"
                />
              ) : (
                <img
                  className="logo logo-collapsed"
                  src={imageLogoSmall}
                  height="20"
                  width="42"
                  alt="IT&I Software"
                />
              )}
            </Link>
            <div className="d-flex d-md-none justify-content-center align-items-center ml-auto py-4">
              <img
                alt="Menü"
                src={iconNavigationOpen}
                width={25}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setIsSidebarOpen(true)
                  disableBodyScroll()
                }}
              />
            </div>
            <Nav className="ml-auto small d-none d-md-flex pb-0" navbar>
              <div
                className={classnames("navigation with-indicator", {
                  active: isIndicatorVisible,
                })}
              >
                <div
                  className={classnames("nav-item", {
                    active: currentPath.includes("/services"),
                  })}
                >
                  <Link to="/services">Leistungen</Link>
                  {navigationGroups.services.length ? (
                    <NavigationGroup items={tail(navigationGroups.services)} />
                  ) : null}
                </div>
                <div
                  className={classnames("nav-item", {
                    active: currentPath.includes("/technologies"),
                  })}
                >
                  <Link to="/technologies">Technologien</Link>
                  {navigationGroups.technologies.length ? (
                    <NavigationGroup
                      items={tail(navigationGroups.technologies)}
                    />
                  ) : null}
                </div>
                <div
                  className={classnames("nav-item", {
                    active: currentPath.includes("/about"),
                  })}
                >
                  <Link to="/about">Über uns</Link>
                  {navigationGroups.about.length ? (
                    <NavigationGroup items={tail(navigationGroups.about)} />
                  ) : null}
                </div>
              </div>
              <Link
                to={jobsNavigation.url}
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex align-items-center ml-2"
              >
                <Button color="dark" size="sm">
                  {jobsNavigation.label}
                </Button>
              </Link>
            </Nav>
          </Row>
        </Container>
      </Navbar>
    </>
  )
}

export default Navigation
